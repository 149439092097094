import { useEffect, useState } from 'react';
import queryString from 'query-string';

import Attribution, { LinkTarget } from '@web-solutions/module-attribution';

export const useGetUrl = () => {
  const [url, setUrl] = useState('');

  const { platform } = queryString.parse(window.location.search);

  const appLink = Attribution.getLinkToApp(platform ? platform === 'web' ? LinkTarget.WEB : LinkTarget.DEFAULT : undefined);

  useEffect(() => {
    const fetchUrl = async () => {
      let newUrl = appLink;

      if (platform) {
        const parsedUrl = queryString.parseUrl(appLink);

        if (platform === 'web') {
          newUrl = queryString.stringifyUrl({
            url: `${parsedUrl.url}settings`,
            query: parsedUrl.query,
          });
        } else {
          parsedUrl.query.pathname = 'settings';

          newUrl = queryString.stringifyUrl(parsedUrl);
        }
      }

      setUrl(newUrl);
    };

    fetchUrl();
  }, [appLink, platform]);

  return url;
};
